'use client';

import { useEffect, useState } from 'react';
import '@/components/builder/login/LoginCTA';
import { LoginCTA } from '@/components/builder/login/LoginCTA';
import { reportToSentry } from '@/utils/reportToSentry';
import { BuilderComponent, BuilderContent } from '@builder.io/react';
import { builder } from '@builder.io/react';
export default function LoginHeading() {
  const [loginPageData, setLoginPageData] = useState<BuilderContent<any> | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    builder.get(`login-page-cta`).toPromise().then(loginPageData => {
      setLoginPageData(loginPageData);
      setIsLoading(false);
    }).catch(error => {
      console.error(`Error fetching login page data from builder.io:`, error);
      reportToSentry(new Error(`Failed to fetch login page data from builder.io`));
      setIsLoading(false);
    });
  }, []);
  if (isLoading || !loginPageData) {
    return <LoginCTA headerText="Time to Reorder Your Continence Care Supplies!" subHeaderText="Use your phone number or email and date of birth to log in and reorder your supplies below." phoneNumberText="If you have any questions, please call us at" phoneNumber="844-276-5588" />;
  }
  return <BuilderComponent model="login-page-cta" context={loginPageData} data-sentry-element="BuilderComponent" data-sentry-component="LoginHeading" data-sentry-source-file="LoginHeading.tsx" />;
}