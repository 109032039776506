'use client';

import React, { useState } from 'react';
import { useFormStatus } from 'react-dom';
import { SfInput, SfButton } from '@/components/ui/sf';
import { useChecklistStore } from '@/stores/checklist';
import { useClaimsStore } from '@/stores/claims';
import { SfIconLock, SfLoaderCircular, SfRadio } from '@storefront-ui/react';
import Formatters from '@/utils/formatters';
import { isDateReal } from '@/utils/utils';
function LoginFormContent({
  register,
  errors,
  isValid,
  state,
  resetField,
  setFocus,
  getValues
}) {
  const [checkedState, setCheckedState] = useState<string>('phone');
  const [dobError, setDobError] = useState<string>('');
  const status = useFormStatus();
  if (!status) {
    // explicitly handling for visibility when useFormStatus returns null
    // eslint-disable-next-line no-console
    console.warn('useFormStatus is: ', status);
  }
  const {
    pending = false
  } = status || {};
  const {
    reset: resetClaims,
    setOrderWasPlaced
  } = useClaimsStore();
  const {
    reset: resetChecklist
  } = useChecklistStore();
  const radioOptions = [{
    label: 'Phone Number',
    value: 'phone',
    name: 'phone-checkbox'
  }, {
    label: 'Email',
    value: 'email',
    name: 'email-checkbox'
  }];
  if (pending) return <div className="max-w-lg mx-auto flex flex-col items-center justify-center mt-10 gap-4">
        <p className="font-light w-64 text-center">Please hold tight while we get your details...</p>
        <SfLoaderCircular size="3xl" />
      </div>;
  const checkFullValidDate = () => {
    const now = new Date();
    const [month, day, year] = getValues(['dob-month', 'dob-day', 'dob-year']);
    const dob = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    return month && day && year?.length === 4 && (dob > now || now.getFullYear() - dob.getFullYear() >= 120 || !isDateReal(month, day, year)) ? setDobError('Date of birth must be valid and in the past') : setDobError('');
  };
  return <>
      <fieldset role="radiogroup">
        <div className="flex justify-center items-center px-4 w-fit mx-auto mb-[36px] gap-[23px]">
          {radioOptions.map(({
          label,
          value,
          name
        }) => <label key={value} className="flex items-center cursor-pointer">
              <SfRadio name={name} data-testid={name} value={value} checked={checkedState === value} className="checked:bg-primary-700 checked:border-[#D8D8D8] border-[#D8D8D8] hover:border-primary-800 focus-visible:outline-primary-400" onChange={event => {
            setCheckedState(event.target.value);
            resetField(event.target.value === 'phone' ? 'email' : 'phone');
          }} />
              <span className="ml-2 text-base leading-6 font-normal">{label}</span>
            </label>)}
        </div>
      </fieldset>
      <div className="mx-auto px-4">
        {checkedState === 'email' && <label className="text-sm font-medium mb-2" htmlFor="email">
            <p className="pb-3">Email:</p>
            <SfInput {...register('email')} type="email" name="email" data-testid="email-input" placeholder="example@example.com" required={checkedState === 'email'} invalid={Boolean(errors?.['email']?.message)} />
            <p className="mt-2 text-error text-center mx-auto">{errors?.['email']?.message}</p>
          </label>}
        {checkedState === 'phone' && <label className="text-sm font-medium mb-2" htmlFor="phone">
            <p className="pb-3">Phone:</p>
            <SfInput type="tel" name="phone" data-testid="phone-input" placeholder="xxx-xxx-xxxx" required={checkedState === 'phone'} {...register('phone', {
          onChange: (e: {
            target: {
              value: any;
            };
          }) => {
            e.target.value = Formatters.phone(e.target.value);
          }
        })} invalid={Boolean(errors?.['phone']?.message)} />
            <p className="mt-2 text-error text-center mx-auto">{errors?.['phone']?.message}</p>
          </label>}
        <br></br>
        <label className="text-sm font-medium" id="dob">
          <p className="pb-3">{`Patient's Date of Birth:`}</p>
          <div className="flex justify-between gap-4">
            <div className="flex flex-col">
              <SfInput type="tel" name="dob-month" id="dob-month" className="text-center" data-testid="dob-month-input" placeholder="MM" maxLength={2} {...register('dob-month', {
              onChange: (e: {
                target: {
                  value: any;
                };
              }) => {
                e.target.value = Formatters.numericCharacters(e.target.value);
                checkFullValidDate();
                if (e.target.value?.length === 2 || parseInt(e.target.value) >= 2) setFocus('dob-day');
              }
            })} invalid={Boolean(errors?.['dob-month']?.message)} required data-sentry-element="SfInput" data-sentry-source-file="LoginFormContent.tsx" />
              <p className="mt-2 text-error text-center w-min mx-auto" data-testid="dob-month-error">
                {errors?.['dob-month']?.message}
              </p>
            </div>
            <div className="flex flex-col">
              <SfInput type="tel" name="dob-day" id="dob-day" className="text-center" maxLength={2} data-testid="dob-day-input" placeholder="DD" {...register('dob-day', {
              onChange: (e: {
                target: {
                  value: any;
                };
              }) => {
                e.target.value = Formatters.numericCharacters(e.target.value);
                checkFullValidDate();
                if (e.target.value?.length === 2 || parseInt(e.target.value) >= 4) setFocus('dob-year');
              }
            })} invalid={Boolean(errors?.['dob-day']?.message)} required data-sentry-element="SfInput" data-sentry-source-file="LoginFormContent.tsx" />
              <p className="mt-2 text-error text-center w-min mx-auto" data-testid="dob-day-error">
                {errors?.['dob-day']?.message}
              </p>
            </div>
            <div className="flex flex-col">
              <SfInput type="tel" id="dob-year" name="dob-year" className="text-center" data-testid="dob-year-input" placeholder="YYYY" maxLength={4} {...register('dob-year', {
              onChange: (e: {
                target: {
                  value: any;
                };
              }) => {
                e.target.value = Formatters.numericCharacters(e.target.value);
                checkFullValidDate();
              }
            })} invalid={Boolean(errors?.['dob-year']?.message)} required data-sentry-element="SfInput" data-sentry-source-file="LoginFormContent.tsx" />
              <p className="mt-2 text-error text-center w-min mx-auto" data-testid="dob-year-error">
                {errors?.['dob-year']?.message}
              </p>
            </div>
          </div>
        </label>
      </div>
      {dobError && <p className="mt-2 text-error text-center">{dobError}</p>}
      <div className="flex flex-col gap-3 justify-center my-8 items-center">
        <SfButton type="submit" data-testid="login-button" disabled={pending || Boolean(Object.keys(errors)?.length && !Object.keys(errors).includes('submit')) || !isValid || !getValues('email') && !getValues('phone') || Boolean(dobError)} onClick={() => {
        resetClaims();
        resetChecklist();
        setOrderWasPlaced(null);
      }} data-sentry-element="SfButton" data-sentry-source-file="LoginFormContent.tsx">
          <SfIconLock data-sentry-element="SfIconLock" data-sentry-source-file="LoginFormContent.tsx" />
          <p className="mt-0.5">Log In</p>
        </SfButton>
        {state?.errors?.length && state?.errors[0]?.message && <p className="text-error text-center max-w-md mx-auto" data-testid="login-error">
            {state.errors[0].message}
          </p>}
      </div>
    </>;
}
export default LoginFormContent;