export const Formatters = {
  phone: (value: any) => {
    if (!value) return value;
    const _val = value.replace(/[- )(]/g, ``).replace(/[^0-9]/g, ``);

    if (_val.length === 1 && _val === `1`) {
      return ``;
    }

    const a = _val.length > 3 ? `${_val.substring(0, 3)}` : _val;
    const b = _val.substring(3, 6) ? `-${_val.substring(3, 6)}` : ``;
    const c = _val.substring(6) ? `-${_val.substring(6, 10)}` : ``;

    // pattern `(888) 555-1212`
    return `${a}${b}${c}`;
  },
  numericCharacters: (value: string) => {
    if (/^\d+$/.test(value)) return value;

    return value?.replace(/\D/g, '');
  },
  prefixWithZero: (value: string) => {
    if (value.length === 1) {
      return `0${value}`;
    }
    return value;
  },
};

export default Formatters;
