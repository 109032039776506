import { Builder } from '@builder.io/react';
type LoginCTAProps = {
  headerText: string;
  subHeaderText: string;
  phoneNumberText: string;
  phoneNumber: string;
};
export const LoginCTA = ({
  headerText,
  subHeaderText,
  phoneNumberText,
  phoneNumber
}: LoginCTAProps) => {
  return <div className="flex flex-col gap-2" data-sentry-component="LoginCTA" data-sentry-source-file="LoginCTA.tsx">
      <h1 className="text-2xl text-center py-4">{headerText}</h1>
      <p className="text-sm text-center mx-auto max-w-xs px-4">{subHeaderText}</p>
      <div className={`text-sm text-center max-w-xs mx-auto py-4`}>
        <p className={`text-sm text-center`}>{phoneNumberText}</p>
        <a href="tel:844-276-5588">{phoneNumber}</a>
      </div>
    </div>;
};
Builder.registerComponent(LoginCTA, {
  name: `login-heading`,
  inputs: [{
    name: `headerText`,
    type: `text`,
    defaultValue: `Time to Reorder Your Continence Care Supplies!`
  }, {
    name: `subHeaderText`,
    type: `text`,
    defaultValue: `Use your phone number or email and date of birth to log in and reorder your supplies below.`
  }, {
    name: `phoneNumberText`,
    type: `text`,
    defaultValue: `If you have any questions, please call us at`
  }, {
    name: `phoneNumber`,
    type: `text`,
    defaultValue: `844-276-5588`
  }]
});