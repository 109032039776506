'use client';

import { useFormState } from 'react-dom';
import { useForm } from 'react-hook-form';
import { userLogin } from '@/actions/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import LoginFormContent from './LoginFormContent';
import { loginSchema } from './validation';
import { useSearchParams } from 'next/navigation';
export interface FormValues {
  email: string;
  phone: string;
  'dob-month': string;
  'dob-day': string;
  'dob-year': string;
}
export default function LoginForm() {
  const {
    register,
    formState,
    setFocus,
    resetField,
    getValues
  } = useForm<FormValues>({
    resolver: zodResolver(loginSchema),
    shouldFocusError: true,
    mode: `onBlur`
  });
  const searchParams = useSearchParams();
  const next = searchParams.get('next');
  const [state, formAction] = useFormState(userLogin, {
    navigateTo: next
  });
  const {
    errors,
    isValid
  } = formState;
  return <>
      <h1 className="font-medium text-center mb-[13px] mt-[32px]">Log In With:</h1>
      <form action={formAction} className="mx-auto flex flex-col items-center justify-center">
        <LoginFormContent register={register} errors={errors} isValid={isValid} state={state} resetField={resetField} setFocus={setFocus} getValues={getValues} data-sentry-element="LoginFormContent" data-sentry-source-file="LoginForm.tsx" />
      </form>
    </>;
}