'use client';

import { ClaimItem } from '@/components/pages/claim/types';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export interface SelectedItem extends ClaimItem {
  qty: number;
  selected?: boolean;
}

interface ClaimsStore {
  selectedItems: SelectedItem[]; // We will use the dme ID as the unique identifier
  orderWasPlaced: string | null;
  threeMonthPromoAccepted: boolean;
  setOrderWasPlaced: (date: string | null) => void;
  reset: () => void;
  setSelectedItems: (items: ClaimItem[]) => void;
  addSelectedItem: (qty: number, item: ClaimItem) => void;
  removeSelectedItem: (dmeId: string) => void;
  clearSelectedItems: () => void;
  getIsItemSelected: (dmeId: string) => boolean;
  toggleSelectedItem: (qty: number, item: ClaimItem) => void;
  addAllClaims: (items: ClaimItem[]) => void;
  updateQty: (dmeId: string, qty: number) => void;
  setThreeMonthPromoAccepted: (eligible: boolean) => void;
}

export const useClaimsStore = create<ClaimsStore>()(
  persist(
    (set, get) => ({
      selectedItems: [],
      orderWasPlaced: null,
      threeMonthPromoAccepted: false,
      setThreeMonthPromoAccepted: (eligible: boolean) => set({ threeMonthPromoAccepted: eligible }),
      setOrderWasPlaced: (date: string | null) => set({ orderWasPlaced: date }),
      reset: () => set({ selectedItems: [] }),
      setSelectedItems: (items: SelectedItem[]) => {set({ selectedItems: items })},
      addSelectedItem: (qty: number, item: ClaimItem) =>
        set((state) => ({ selectedItems: [...state.selectedItems, { ...item, qty }] })),
      removeSelectedItem: (dmeId: string) =>
        set((state) => ({ selectedItems: state.selectedItems.filter((item) => item.dmeId !== dmeId) })),
      clearSelectedItems: () => set({ selectedItems: [] }),
      getIsItemSelected: (dmeId: string) => Boolean(get().selectedItems.find((item) => item.dmeId === dmeId)),
      toggleSelectedItem: (qty: number, item: ClaimItem) =>
        get().getIsItemSelected(item.dmeId) ? get().removeSelectedItem(item.dmeId) : get().addSelectedItem(qty, item),
      addAllClaims: (items: ClaimItem[]) => {
        const newItems = items
          .filter((item: ClaimItem) => {
            return new Date(item.eligible) <= new Date();
          })
          .map((item: ClaimItem) => {
            return Object.assign({}, item, {
              qty: item.maxQty - (item.maxQty % item.explodeFactor),
            });
          });
        set({ selectedItems: [...newItems] });
      },
      updateQty: (dmeId: string, qty: number) => {
        if (!get().getIsItemSelected(dmeId)) return;

        const updatedItems = get().selectedItems;

        const itemIndex = updatedItems.findIndex((item) => item.dmeId === dmeId);
        updatedItems[itemIndex].qty = qty;

        set({ selectedItems: [...updatedItems] });
      },
    }),
    {
      name: 'claims-storage',
      storage: createJSONStorage(() => sessionStorage),
      skipHydration: true, // We do this to avoid rehydrating the store on the server
    },
  ),
);
