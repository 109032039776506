"use client"

import ChecklistItem from '@/types/checklistItem';
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface ChecklistStore {
  checklist: Map<string, ChecklistItem>;
  addChecklistItem: ( hcpc: string, response: 'true' | 'false' ) => void;
  removeChecklistItem: (hcpc: string) => void;
  getChecklistArray: () => ChecklistItem[];
  preselectYes: ( hcpcItems: string[] ) => void;
  reset: () => void;
}

export const useChecklistStore = create<ChecklistStore>()(persist( (set, get) => ({
  checklist: new Map(),
  reset: () => set({ checklist: new Map() }),
  preselectYes: ( hcpcItems: string[] ) => {
    // preselect yes for items that have a medicare checklist on initial load
    if ( get().checklist.size === 0 ) {
      hcpcItems.forEach( ( hcpc: string ) => {
        get().addChecklistItem( hcpc, 'true' )
      })
    }
  },
  addChecklistItem: ( hcpc: string, response: 'true' | 'false' ) => {
    const checklist = get().checklist;
    const item = checklist.get( hcpc.toString() );
    if ( item ) {
      item.reason.quantityQuestionResponse = response; // will need to be converted to snake case in the order submission body
      item.reason.qualityQuestionResponse = 'true';
      checklist.set( hcpc.toString(), item );
    } else {
      const newItem:ChecklistItem = Object.assign( {}, {
        hcpc: parseInt( hcpc ),
        reason: {
          quantityQuestionResponse: response,
          qualityQuestionResponse: 'true'
        }
      } as ChecklistItem )
      checklist.set( hcpc.toString(), newItem )
    }

    set({ checklist: new Map( checklist ) });
  },
  removeChecklistItem: (hcpc: string ) => {
    const checklist = get().checklist;
    checklist.delete( hcpc.toString() );
    set({ checklist: new Map( checklist ) });
  },
  getChecklistArray: () => {
    return Array.from( get().checklist, ( [ _name, value ] ) => ( value ) )
  }
}),{
  name: 'checklist-storage',
  storage: {
    getItem: (key: string) => {
      const str = sessionStorage.getItem( key );
      return {
        state: {
          checklist: new Map( JSON.parse( str ).state.checklist )
        }
      }
    },
    setItem: (key: string, value: any) => {
      const str = JSON.stringify({
        state: {
          checklist: Array.from( value.state.checklist.entries() )
        }
      })
      sessionStorage.setItem( key, str )
    },
    removeItem: ( key: string ) => sessionStorage.removeItem( key )
  },
  skipHydration: true // We do this to avoid rehydrating the store on the server
}))
