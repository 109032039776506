import { z } from 'zod';
import { zfd } from 'zod-form-data';

export const loginSchema = zfd.formData(
  z.object({
    phone: z
      .string()
      .regex(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/, 'Invalid phone number')
      .length(12, 'Invalid phone number')
      .optional(),
    email: z.string().email('Invalid email address').min(1, 'Email is required').optional(),
    'dob-day': z
      .coerce
      .string({ required_error: 'Date is required' })
      .min(1, 'Date is required')
      .refine((dd) => {
        const day = parseInt(dd);
        return !isNaN(day) && day >= 1 && day <= 31;
      }, 'Date must be between 1 and 31'),
    'dob-month': z
      .coerce
      .string({ required_error: 'Month of birth is required' })
      .min(1, 'Month is required')
      .refine((mm) => {
        const month = parseInt(mm);
        return !isNaN(month) && month >= 1 && month <= 12;
      }, 'Month must be between 1 and 12'),
    'dob-year': z
      .coerce.string().length(4, 'Year is required')
  }),
);
